<template>
  <v-row class="statistic">
    <v-col cols="12" class="pa-0">
      <div class="title justify-space-between">
        <div class="white--text text-uppercase text-sm-h5 text-h6 font-weight-bold">Messages statistics</div>
        <div class="d-md-flex d-none">
          <div class="white--text text-body-1">Total sent: {{ data.total_count }}</div>
          <v-divider style="min-height: 24px; height: 24px" class="white mx-5" vertical></v-divider>
          <div class="white--text text-body-1">Total price: {{ data.total }}</div>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-sm-15">
        <div class="accent py-2 px-sm-10 px-2 custom">
          <v-card width="100%" color="accent" flat class="d-md-flex align-md-end">
            <v-autocomplete
              v-model="account"
              :items="accountsList"
              prepend-inner-icon="mdi-magnify"
              dense
              outlined
              color="primary"
              item-text="name"
              item-value="id"
              hide-details
              height="40"
              class="mr-5 my-sm-0 my-1"
              @change="getData"
            ></v-autocomplete>
            <v-select
              v-model="date"
              :items="periods"
              item-text="title"
              item-value="name"
              dense
              outlined
              hide-details
              color="primary"
              height="40"
              @change="getData"
              class="mr-5 my-sm-0 my-1"
            ></v-select>
            <span v-if="date == 'custom'">
              <v-menu v-model="modalFrom" :close-on-content-click="false" fixed transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mr-5 my-sm-0 my-1"
                    v-model="dateFrom"
                    placeholder="From"
                    hide-details
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    outlined
                    required
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    height="40"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :max="dateTo"
                  color="primary"
                  v-model="dateFrom"
                  @input="modalFrom = false"
                  @change="getData"
                ></v-date-picker>
              </v-menu>
              <v-menu v-model="modalTo" :close-on-content-click="false" fixed transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mr-5 my-sm-0 my-1"
                    v-model="dateTo"
                    placeholder="To"
                    hide-details
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    outlined
                    required
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    height="40"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :min="dateFrom"
                  :max="new Date().toISOString().substr(0, 10)"
                  color="primary"
                  v-model="dateTo"
                  @input="modalTo = false"
                  @change="getData"
                ></v-date-picker>
              </v-menu>
            </span>
            <v-btn v-if="date == 'custom' || !data.messages.length" text color="gray" @click="clear">
              Clear <v-icon>mdi-close mdi-18px</v-icon>
            </v-btn>
          </v-card>
        </div>
        <div>
          <div v-if="nothing" class="pb-3">
            <div class="d-flex ms-md-8 ms-3">
              <div style="max-width: 190px" class="opasity--text f18 font-weight-medium text-center mt-10">
                Select an account to show statements
              </div>
              <img src="@/assets/icon/arrow-up.svg" alt="arrow" />
            </div>
            <v-card flat height="55vh" min-height="240" class="d-flex align-center justify-center flex-column">
              <v-img class="mt-7" max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
              <div class="text-center text-h5 font-weight-bold mt-8">Sorry, but nothing found</div>
            </v-card>
          </div>
          <div v-else-if="block || !data.messages.length" class="pb-3">
            <div class="d-flex ms-md-8 ms-3">
              <div style="max-width: 190px" class="opasity--text f18 font-weight-medium text-center mt-10">
                Select an account to show statements
              </div>
              <img src="@/assets/icon/arrow-up.svg" alt="arrow" />
            </div>
            <v-card flat height="55vh" min-height="240" class="d-flex align-center justify-center flex-column">
              <v-img class="mt-7" max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
              <div class="text-center text-h6 font-weight-bold mt-8">There is no record to show</div>
            </v-card>
          </div>
          <div v-else>
            <v-data-table
              hide-default-footer
              fixed-header
              :headers="headers"
              :items="data.messages"
              :height="'calc(100vh - 235px)'"
              class="d-sm-block d-none p-table"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id" height="52">
                    <td class="text-capitalize">{{ data.account.name }}</td>
                    <td>{{ item.country }}</td>
                    <td>{{ item.operator }}</td>
                    <td>{{ item.mcc }}/{{ item.mnc }}</td>
                    <td>{{ data.total_count }}</td>
                    <td>{{ item.count }}</td>
                    <td class="text-end">{{ item.total }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
            <div class="d-block d-sm-none">
              <div v-for="item in data.messages" :key="item.id" class="px-4 pt-2">
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Account</div>
                  <div class="font-weight-medium f18">{{ data.account.name }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Country</div>
                  <div class="font-weight-medium f18">{{ item.country }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Operator</div>
                  <div class="font-weight-medium f18">{{ item.operator }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">MCC/MNC</div>
                  <div class="font-weight-medium f18">{{ item.mcc }}/{{ item.mnc }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Sent</div>
                  <div class="font-weight-medium f18">{{ data.total_count }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Delivered</div>
                  <div class="font-weight-medium f18">{{ item.count }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Total</div>
                  <div class="font-weight-medium f18">{{ item.total }}</div>
                </div>
                <v-divider></v-divider>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      load: true,
      account: this.$route.query.account || 'all',
      date: this.$route.query.date || 'last_7_days',
      dateFrom: this.$route.query.date_from || new Date().toISOString().substr(0, 10),
      dateTo: this.$route.query.date_to || new Date().toISOString().substr(0, 10),
      modalFrom: false,
      modalTo: false,
      block: true,
      nothing: false,
      headers: [
        { text: 'Account', value: 'account', sortable: false },
        { text: 'Country', value: 'country', sortable: false },
        { text: 'Operator', value: 'operator', sortable: false },
        { text: 'MCC/MNC', value: 'mcc', sortable: false },
        { text: 'Sent', value: 'sent', sortable: false },
        { text: 'Delivered', value: 'delivered', sortable: false },
        { text: 'Total', align: 'end', value: 'total', sortable: false },
      ],
      statusError: null,
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getPeriods');
    this.$store.dispatch('getAccountsAll').then(() => {
      this.account == 'all' ? (this.account = this.accountsList[0].id) : '';
      setTimeout(() => {
        this.getData();
      }, 200);
    });
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({ message: 'Error', type: 'error', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    clear() {
      this.account = this.accountsList[0].id;
      this.date = 'last_7_days';
      this.dateFrom = new Date().toISOString().substr(0, 10);
      this.dateTo = new Date().toISOString().substr(0, 10);
      this.getData();
    },
    async getData() {
      await this.$store
        .dispatch('getMessagesStatis', {
          account: this.account,
          date: this.date,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
        })
        .then(() => {
          this.nothing = false;
          this.block = false;
        })
        .catch((e) => {
          this.getDataError(e);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (e.response.data.error[0] === 'account__not_found') {
        this.$store.dispatch('setMessagesList', {});
        this.block = true;
      } else if (e.response.data.error[0] === 'result__not_found') {
        this.$store.dispatch('setMessagesList', {});
        this.nothing = true;
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.messagesStatis;
    },
    periods() {
      return this.$store.getters.periods;
    },
    accountsList() {
      return this.$store.getters.accountsAll;
    },
  },
  destroyed() {
    this.$store.dispatch('setMessagesStatis', { messages: [] });
    this.$store.dispatch('setAccountsAll', []);
  },
};
</script>

<style lang="scss">
.statistic {
  .custom {
    .v-input {
      display: inline-block;
      max-width: 160px;
      width: 100%;
      .v-input__append-inner {
        margin-top: 8px !important;
      }
    }
    .v-autocomplete {
      max-width: 240px;
    }
  }
}
</style>
